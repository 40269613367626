<template>
  <div class="container m-auto min-h-screen black flex flex-col justify-center items-center">
    <h1 class="text-[48px] leading-[40px] mb-5">
      Einladung
    </h1>

    <h2 class="text-[28px] leading-[40px] mb-5 text-center">
      zu meinem <br>
      31. Geburtstag
    </h2>

    <span>
      <p>
        Am 01.04.2025 werde ich 31.
      </p>
      <p>
        Das möchte ich mit Euch feiern.
      </p>
    </span>

    <hr class="my-5 w-20 mx-auto border-black">

    <span>
      <p class="mb-5">
        Wann:
        <button class="underline font-bold cursor-pointer" @click.prevent="downloadEvent">
          05.04.2024, ab16:00 Uhr
        </button>
      </p>

      <p>
        Wo: <a class="underline font-bold cursor-pointer"
          href="https://www.google.de/maps/dir/49.1977449,8.293437/Am+Hasenspiel,+76756+Bellheim/@49.1981386,8.2910164,17z/data=!3m1!4b1!4m19!1m8!3m7!1s0x4797ad2ddb1d0045:0xcfdf0c7c65c94fe1!2sAm+Hasenspiel,+76756+Bellheim!3b1!8m2!3d49.1985448!4d8.2937456!16s%2Fg%2F1thxplk0!4m9!1m1!4e1!1m5!1m1!1s0x4797ad2ddb1d0045:0xcfdf0c7c65c94fe1!2m2!1d8.2937456!2d49.1985448!3e0?entry=ttu&g_ep=EgoyMDI1MDMxMS4wIKXMDSoASAFQAw%3D%3D"
          target="_blank">
          Am Hasenspiel 15, Bellheim
        </a>
      </p>
    </span>

    <hr class="my-5 w-20 mx-auto border-black">

    <span>
      <p class="mb-5">
        Bitte gebt mir rechtzeitig Bescheid, ob Ihr dabei seid.

        <br>

      </p>

      <button @click.prevent="openDialog"
        class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        Anmelden
      </button>
    </span>

    <p class="text-[28px] leading-[20px] font-bold mt-10">
      Alex
    </p>

    <ModalDialog @closeDialog="closeDialog" :showDialog="dialogIsVisible"></ModalDialog>

  </div>
</template>


<script>
import { createEvent } from "ics";
import { saveAs } from "file-saver";

import ModalDialog from "./Modal/ModalDialog.vue";

export default {
  data() {
    return {
      event: {
        start: [2025, 4, 5, 16, 0],
        duration: { hours: 6 },
        title: "Alex 31. Geburstag",
        description: "Geburstagsfeier",
        location: "76756 Bellheim  Am Hasenspiel 15",
        status: "CONFIRMED",
        organizer: { name: "Alex", email: "alexanderhuxel@gmail.com" },
      },
      dialogIsVisible: false,
    };
  },
  components: {
    ModalDialog
  },
  methods: {
    downloadEvent() {
      createEvent(this.event, (error, value) => {
        const blob = new Blob([value], { type: "text/calendar" });
        saveAs(blob, "event-schedule.ics");
      });
    },
    openDialog() {
      this.dialogIsVisible = true;
    },
    closeDialog() {
      this.dialogIsVisible = false;
    },
  }
};
</script>


<style scoped>
div {
  @apply py-10 z-10 relative text-white;
}
</style>